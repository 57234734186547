import React from "react"

import Layout from "components/Layout"
import { Confirmation } from "page_components/hugo-scobel-landing"

const PromotionConfirmation = ({ location }) => {
  const title = "Browar Hugo Scobel - potwierdzenie"

  return (
    <Layout
      location={location}
      seo={{
        title,
        description: "",
      }}
      rmHeader
      rmFooter
    >
      <Confirmation />
    </Layout>
  )
}

export default PromotionConfirmation
