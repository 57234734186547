import "./styles.scss"

import React from "react"

import { Form } from "./components"
import { Link } from "gatsby"

const Landing = () => {
  return (
    <section className="hs-landing">
      <div className="hs-landing__logo">
        <img
          src={require("assets/images/hugo-scobel-landing/logo-hs.svg").default}
          alt="BROWAR HUGO SCOBEL"
        />
      </div>
      <div className="hs-landing__main">
        <div className="hs-landing__wrapper">
          <div className="hs-landing__brand">
            <Link to="/">
              <img
                src={
                  require("assets/images/hugo-scobel-landing/logo-ti.svg")
                    .default
                }
                alt="Trust Investment"
              />
            </Link>
          </div>
          <h1>
            Legenda Gliwic
            <br />
            powraca
          </h1>
          <h2>Etap I: DOM POD LWEM wkrótce w sprzedaży!</h2>
          <h3>NIE CZEKAJ, POZNAJ OFERTĘ</h3>
          <Form />
        </div>
      </div>
      <div className="hs-landing__aside">
        <div
          className="hs-landing__background"
          style={{
            backgroundImage: `url(${
              require("assets/images/hugo-scobel-landing/landing-image.jpg")
                .default
            })`,
          }}
        >
          <div
            className="hs-landing__background-inner"
            style={{
              backgroundImage: `url(${
                require("assets/images/hugo-scobel-landing/landing-image-inner.png")
                  .default
              })`,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default Landing
